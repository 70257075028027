#gldiv {
    position: absolute;
    height: 100%;
    width: 80%;
    right: 26em; 
    left: 0em; 
    margin: 0px;
    padding: 0px;
    border: 0px;
    background: #ccc;
}

#outer {
  width: 20%;
  /*width: 30%;*/
  right: 0px;
  top: 0em;
  height: 100%;
  position: absolute; 
  overflow: auto;
  padding: 1em;
  background: #ccc;
}
