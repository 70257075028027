	/*
switch1 = on/off surface
switch2 = opacity
switch3 = labels

	*/
.switch1{
	position: relative;
	display: block;
	vertical-align: top;
	width: 80px;
	height: 30px;
	padding: 3px;
	margin: 0 10px 10px 0;
	/* background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px); */
	/* background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px); */
	border-radius: 18px;
	/* box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05); */
	cursor: pointer;
}
.switch2{
	position: relative;
	display: block;
	vertical-align: top;
	width: 115px;
	height: 30px;
	padding: 3px;
	margin: 0 10px 10px 0;
	/* background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px); */
	/* background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px); */
	border-radius: 18px;
	/* box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05); */
	cursor: pointer;
}

.switch3{
	position: relative;
	display: block;
	vertical-align: top;
	width: 85px;
	height: 30px;
	padding: 3px;
	margin: 0 10px 10px 0;
	/* background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px); */
	/* background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px); */
	border-radius: 18px;
	/* box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05); */
	cursor: pointer;
}

.switch1-input, .switch2-input, .switch3-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
}
.switch1-label, .switch2-label, .switch3-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 9px;
	text-transform: uppercase;
	background: #eceeef;
	border-radius: inherit;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
}
.switch1-label:before, .switch1-label:after, .switch2-label:before, .switch2-label:after, .switch3-label:before, .switch3-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
}
.switch1-label:before, .switch2-label:before, .switch3-label:before {
	content: attr(data-off);
	right: 11px;
	color: #000000;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch1-label:after, .switch2-label:after, .switch3-label:after {
	content: attr(data-on);
	left: 11px;
	color: #FFFFFF;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}
.switch1-input:checked ~ .switch1-label, .switch2-input:checked ~ .switch2-label, .switch3-input:checked ~ .switch3-label {
	background: #999;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch1-input:checked ~ .switch1-label:before, .switch2-input:checked ~ .switch2-label:before, .switch3-input:checked ~ .switch3-label:before {
	opacity: 0;
}
.switch1-input:checked ~ .switch1-label:after, .switch2-input:checked ~ .switch2-label:after, .switch3-input:checked ~ .switch3-label:after {
	opacity: 1;
}
.switch1-handle, .switch2-handle, .switch3-handle {
	position: absolute;
	top: 4px;
	left: 4px;
	width: 28px;
	height: 28px;
	/* background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0); */
	/* background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0); */
	border-radius: 100%;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch1-handle:before, .switch2-handle:before, .switch3-handle:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -6px;
	width: 12px;
	height: 12px;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
	border-radius: 6px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch1-input:checked ~ .switch1-handle {
	left: 50px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch2-input:checked ~ .switch2-handle {
	left: 80px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch3-input:checked ~ .switch3-handle {
	left: 55px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

 
/* Transition
========================== */
.switch1-label, .switch1-handle, .switch2-label, .switch2-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}