.logo {
    max-width: 80%;
    /* background: rgba(255, 255, 255, 0.2); */
    margin: 16px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.sidemenu {
    /* height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px; */
    /* min-height: max(100vh, 100%); */
    /* min-height: 100vh; */
    min-height: calc(100% + (100vh - 100%));
    /* max-height: 100%; */
}