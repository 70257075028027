.ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    /* background: #fff; */
    background: #364d79;
    overflow: hidden;
    /* border-radius: 5px; */
    /* z-index: -1; */
    /* box-shadow: 2px 4px 10px #00000033; */
    /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2) */
  }
  
.ant-carousel .slick-slide h3 {
color: #fff;
}

@keyframes fadeInDown{
  from {
    opacity: 0;
    transform: translateY(40%)
  }
}

.banner {
  background-color: #fff; 
  /* height: 50%; */
  /* height: 50vh; */
  box-shadow: 2px 4px 3px #00000033 ;
  height: 500px;
}
.banner h1{
  font-size: 68px;
  animation: 0.7s fadeInDown ;
}
.banner p{
  font-size: 17px;
  animation: 1.0s fadeInDown ;
  /* animation-delay: 3s */
}
.banner .welcome-text {
  /* margin: 50px 0px */
  transform: translate(0%, -5%);
}
.banner .banner-button {
  /* margin: 50px 0px */
  /* transform: translate(0%, 50%) */
  /* background-color: aqua */
  /* outline: dashed  */
  /* margin-left:  16px; */
  margin-right: 16px;
  /* margin-top: 16px; */
  animation: 1.2s fadeInDown ;
}

.landing-bullet {
  /* width: 15%; */
  text-align: center;
}
.landing-bullet .icon{
  font-size: 72pt;
}
.landing-bullet h1{
  /* font-size: 72pt; */
  width: 15%;
  /* text-align: center; */
}
.landing-bullet h2{
  /* font-size: small; */
  width: 15%;
  /* text-align: center; */
}